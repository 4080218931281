import React, { useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'

import { classes } from '../../utils/helpers'
import { useReveal } from '../../hooks/useIntersection'
import useTranslations from '../useTranslations'
import SEO from '../seo'
import ContactSection from '../ContactSection'

import components from '../../styles/components.module.sass'
import styles from './About.module.sass'

import artwork3 from '../../images/artworks/03.png'
import poster from '../../images/video-poster.jpg'

const Section = ({ children, number, title, html, dark, image, pull, last }) => {
  const [observerRef, inViewport] = useReveal()
  const animation = {
    variants: revealAnimation,
    initial: 'hide',
    animate: inViewport ? 'show' : 'hide'
  }

  return (
    <section className={classes(
      styles.Section,
      dark && styles.bgColor,
      pull && styles.pullBottom
    )}>
      <div ref={observerRef}>
        {children &&
          <motion.div className={styles.Images} custom={0} {...animation} >
            {/* <motion.img src={image} alt="Section Image" custom={0} {...animation} /> */}
            {children}
          </motion.div>
        }
        <motion.span id={parseInt(number)} custom={1} {...animation}>
          {number}
        </motion.span>
        <motion.h3 custom={2} {...animation}>
          {title}
        </motion.h3>
        <motion.div
          className={components.MarkdownContent}
          dangerouslySetInnerHTML={{ __html: html }}
          custom={3}
          {...animation}
        />
      </div>
    </section>
  )
}

const SectionImage = ({ children, pull }) => {
  const [observerRef, inViewport] = useReveal()
  return (
    <motion.section
      className={classes(
        styles.SectionImage,
        pull && styles.pullBottom
      )}
      ref={observerRef}
      variants={revealImageAnimation}
      initial="hide"
      animate={inViewport ? 'show' : 'hide'}
    >
      {children}
    </motion.section>
  )
}

const SectionImageWide = ({ children, pull }) =>
  <section className={classes(
    styles.SectionImageWide,
    pull && styles.pullBottom
  )}>
    {children}
  </section>

const About = ({ sections, seo }) => {
  const { construction, print3d, planning, electroInst, softwareSolutions } = useTranslations()
  const header = sections.find(({ block }) => block == 'header')
  const [block1, block2, block3, block4, block5, block6, block7] = sections

  return (
    <>
      <SEO {...seo.nodes[0].childPagesJson.about} />
      <header className={styles.HeaderWrapper}>
        <div className={styles.HeaderContainer}>
          <h2 dangerouslySetInnerHTML={{ __html: header.html }} />
        </div>
      </header>

      <Section {...block1}>
        <img src="/assets/partners/siemens.png" alt="Siemens Logo" className={styles.mt} />
        <img src="/assets/partners/designer.png" alt="Process Designer Logo" />
        <img src="/assets/partners/simulate.png" alt="Process Simulate Logo" />
      </Section>

      <Section {...block2} />

      <SectionImage>
        <video controls muted loop poster={poster}>
          <source src="/assets/showreel.mp4" type="video/mp4"/>
        </video>
      </SectionImage>

      <Section {...block3}>
        <img src={artwork3} alt="Realizace projektů" />
      </Section>

      <Section {...block4} dark>
        <img src="/assets/partners/catia.png" alt="Catia Logo" />
      </Section>

      {/* <SectionImageWide>
        <img src="/assets/img/full-width-2.jpg" alt="Section Image" />
      </SectionImageWide> */}

      <Section {...block5}>
        <img src="/assets/partners/eplan.png" alt="ePlan loog" />
      </Section>

      <Section {...block6} dark>
        <img src="/assets/partners/rockwell.png" alt="Rockwell Automation Logo" />
        <img src="/assets/partners/studio5000.png" alt="Studio 5000 Logo" />
        <img src="/assets/partners/tia.png" alt="Siemens TIA Portal Logo" />
      </Section>

      <Section {...block7}>
        <img src="/assets/partners/prusa.png" alt="Prusa Research Logo" className={styles.ma} />
        <img src="/assets/partners/ultimaker.png" alt="Ultimaker Logo" />
      </Section>

      <ContactSection />
    </>
  )
}

export default About

const revealAnimation = {
  hide: {
    opacity: 0,
    x: -20
  },
  show: i => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.12 * i,
      duration: 0.3
    }
  }),
}

const revealImageAnimation = {
  hide: {
    opacity: 0,
    y: -40
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.5
    }
  },
}


export const seoData = graphql`
  fragment SeoData on File {
    name
    childPagesJson {
      about {
        title
        description
      }
    }
  }
`

export const blocksData = graphql`
  fragment BlocksData on MarkdownRemark {
    frontmatter {
      number
      title
      block
    }
    html
  }
`
