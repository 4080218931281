import React, { useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import useTranslations from '../useTranslations'
import SEO from '../seo'
import { MailAndPhone, Address, IDs } from '../Footer'
import { classes } from '../../utils/helpers'
import { TextField, TextAreaField } from '../forms'

import components from '../../styles/components.module.sass'
import styles from './Contact.module.sass'

import img from '../../images/contact-header.png'

const Contact = ({ data: { image, seo }}) => {
  const {
    contactFormSubtitle, contactFormTitle, contactFormDescription,
    name, email, message, send
  } = useTranslations()
  const [isSending, setIsSending] = useState(false)
  const [dirty, setDirty] = useState({
    name: false,
    email: false,
    message: false
  })

  const handleFocus = useCallback(e => {
    const { target } = e
    setDirty(state => ({
      ...state,
      [target.name]: true
    }))
  }, [setDirty])

  const handleBlur = useCallback(e => {
    const { target } = e
    if (!target.value) {
      setDirty(state => ({
        ...state,
        [target.name]: false
      }))
    }
  }, [setDirty])

  return (
    <>
      <SEO {...seo.nodes[0].childPagesJson.contact} />
      <header className={styles.HeaderWrapper}>
        <div className={classes(components.Container, styles.HeaderContainer)}>
          <Image
            className={styles.Image}
            fluid={image.childImageSharp.fluid}
            alt={contactFormDescription}
            style={{ overflow: 'visible '}}
            imgStyle={{ width: 'calc(100% + 141px)', height: 'auto', left: 'unset', right: 0 }}
          />
          <div className={styles.FormWrapper}>
            <div className={classes(components.Form, styles.Form)}>
              <h1>{contactFormSubtitle}</h1>
              <h2>{contactFormTitle}</h2>
              <p>{contactFormDescription}</p>
              <form
                className={isSending ? 'is-sending' : null}
                name="contact"
                action="/kontakt/dekujeme"
                method="POST"
                netlify-honeypot="guest"
                netlify="true"
              >
                <input type="hidden" name="form-name" value="contact" />
                <label className={styles.Trap}>
                  <input name="guest" type="text" />
                </label>
                <TextField
                  name="name"
                  dirty={dirty.name}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                >
                  {name}
                </TextField>
                <TextField
                  name="email"
                  dirty={dirty.email}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                >
                  {email}
                </TextField>
                <TextAreaField
                  name="message"
                  dirty={dirty.message}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                >
                  {message}
                </TextAreaField>
                <button className={components.Button} type="submit">{send}</button>
              </form>
            </div>
          </div>
        </div>
      </header>
      <section className={styles.ContactInfoWrapper}>
        <div className={classes(components.Container, styles.ContactInfo)}>
          <MailAndPhone />
          <Address />
          <IDs />
        </div>
      </section>
      <footer className={styles.Map}>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.6241173679223!2d14.553157423971934!3d50.130755280586875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470becf12aac177f%3A0x30ae3ffbb130db38!2sKpl%20Design%20S.r.o.!5e0!3m2!1scs!2scz!4v1594157246742!5m2!1scs!2scz"
            width="800"
            height="600"
            frameBorder="0"
            style={{ border: 0, width: '100%', height: '100%' }}
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
          />
      </footer>
    </>
  )
}

export default Contact
