import React, { forwardRef } from 'react'

import styles from '../styles/components.module.sass'

export const encode = (data) => Object.keys(data)
  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  .join("&")

const Error = ({ children }) => children
  ? <span className="form-error">{children}</span>
  : null
const Hint = ({ children }) => children
  ? <span className="hint">{children}</span>
  : null

export const TextField = forwardRef(({
  name, children, dirty, error, hint, ...props
}, ref) =>
  <label>
    <input
      ref={ref}
      type="text"
      name={name}
      className={dirty ? styles.dirty : null}
      {...props}
    />
    <span className={styles.Floating}>{children}</span>
    <Hint>{hint}</Hint>
    {error && <Error>{error.message}</Error>}
  </label>
)

export const TextAreaField = forwardRef(({
  name, children, dirty, ...props
}, ref) =>
  <label>
    <textarea
      ref={ref}
      type="text"
      name={name}
      className={dirty ? styles.dirty : null}
      {...props}
    />
    <span className={styles.Floating}>{children}</span>
  </label>
)

export const CheckboxField = forwardRef(({
  name, children, error, ...props
}, ref) =>
  <label>
    <input
      type="checkbox"
      name={name}
      ref={ref}
      {...props}
    />
    <span>{children}</span>
    {error && <Error>{error.message}</Error>}
  </label>
)
